import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "/src/images/fmi-logo-250w.png";


export default function Navi() {

  return (
    <>
 
    <Navbar className="fixed-top" bg="" expand="lg" style={{
      fontFamily:`system-ui`,
      fontWeight:`600`,
      background: `#fff`,
boxShadow: `rgba(0, 0, 0, 0.19) 0px 0px 15px, rgba(0, 0, 0, 0.23) 0px 0 10px`,
    }}>
      <Container>
        <Navbar.Brand href="/"> <img
              src={logo}
              alt="Find My Insurance Logo"
              style={{
                width: `225px`,
                height: `auto`,
                marginRight: `auto`,
              }}
            /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav >
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/articles">Articles</Nav.Link>
            
            <Nav.Link href="/how-to-purchase/">How To Purchase</Nav.Link>
            <Nav.Link href="/contact/" style={{}}>Contact</Nav.Link>
            
            <Nav.Link className="navp btn-l"
            style={{color:`#fff`, marginLeft:`4px`,padding: `10px`,borderRadius: `5px`, margin: `0 auto`, textAlign:`center`}}
              href="/quote/"> Get My Quote</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}



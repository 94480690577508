import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import "./layout.scss"
import Navi from "../components/Navi" 
import Magemail from "../components/magemail";



const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
   <Navi />
   
        <div className="">
          {children}

        </div>
      {/*   <div style={{height: `5rem`,overflow:`hidden`, background:`#fff`}}>
  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: `100%`, transform:`rotate(180deg)`, width: `100%`}}>
    <path d="M0.00,49.98 C149.99,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke:`none`, fill:`orange`}}>
      </path>
      </svg>
      </div>
      <div style={{height: `5rem`,overflow:`hidden`, background:`orange`}}>
  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: `100%`, transform:`rotate(180deg)`, width: `100%`}}>
    <path d="M0.00,49.98 C149.99,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke:`none`, fill:`#fff`}}>
      </path>
      </svg>
    </div>
    
    <Magemail />*/}

        <Footer className="" siteTitle={data.site.siteMetadata.title} />
        
     
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { Row , Col } from "react-bootstrap";

const Footer = ({ siteTitle }) => (
  <>
  <footer style ={{
background: `linear-gradient(0deg, orange, #fbc037,  #fbc03742)`
  }}>

<div style={{marginTop: `5rem`, height: `4rem`,overflow:`hidden` }}>
  <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: `100%`, transform:`rotate(0)`, width: `100%`}}>
    <path d="M0.00,49.98 C149.99,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{stroke:`none`, fill:`#fff`}}>
      </path>
      </svg>
      </div>

<div style={{ 
  minHeight: `300px`, margin: `0 auto`, display: `block`, width:`100%`,}}>
  <Row className="mx-auto" style={{    
display: `flex`,
margin: `0 auto`, 
    padding: `2rem 0`, 
    maxWidth: `600px`,
    background: `transparent`,

    }}>

<Col xs={12} sm={4}     
      className="mx-auto"
      style={{
        margin: `0 auto`,
        maxWidth: `300px`,
        fontSize: `1.5rem`,
        padding: `2rem`,
        textAlign: `right`,
        display:`none`,
        
      }}
    >
     
          
          </Col>

          <Col xs={12} sm={4}     
      className="mx-auto">
      <div style={{ display: `flex`, flexDirection:`column`, alignItems:`center`}}>
      <img alt=" Internet Life Insurance Quotes Since 1994" src="https://res.cloudinary.com/jankless/image/upload/c_scale,w_300/v1627999473/findmyinsurance/1994_jtvtfz.png" 
style={{  display:`flex`, padding: `0.15rem`,  width: `250px`, minWidth: `250px`, maxWidth: `250px`, }} />
  
      <div style={{ maxWidth:`250px`,display: `flex`, flexDirection:`column`,     display:`none`,
}}>
      <a  as={Link} href="/contact" style={{ fontWeight: `bold`, fontSize: `1rem`, lineHeight: `1.15`}}>Contact</a>
<br />
      <a as={Link}   href="mailto:info@findmyinsurance.com"  style={{fontSize: `1rem`, lineHeight: `1.15rem`}}>info@findmyinsurance.com</a>
      <br />
      <a as={Link}   href="tel:+18008881423"  style={{fontSize: `1rem`, lineHeight: `1.15rem`}}>1 (800) 888-1423</a>
      <br />
      </div>
     
</div>

      </Col>
   
  
  

   </Row>
    <div className="container">
  <footer className="py-3 my-2">
    <ul className="nav justify-content-center border-bottom pb-3 mb-3">
    <a className="nav-item"  href="/"  
      style={{fontSize: `1rem`,  padding: `0 0.5rem`, lineHeight: `1.15rem`}}>
      Home   {` `}   </a><br />
      <a className="nav-item" as={Link}  href="/about"
      style={{fontSize: `1rem`,  padding: `0 0.5rem`, lineHeight: `1.15rem`}}>
      About Us      </a><br />
<a className="nav-item"  href="/how-to-purchase"
      style={{fontSize: `1rem`,   padding: `0 0.5rem`, lineHeight: `1.15rem`}}>
      How to Purchase      </a><br />
<a className="nav-item"  href="/articles"
      style={{fontSize: `1rem`,  padding: `0 0.5rem`, lineHeight: `1.15rem`}}>
      Articles      </a><br />
      <a className="nav-item" as={Link} href="/privacy" style={{ fontSize: `1rem`, padding:`0 0.5rem`, lineHeight:`1.15rem`,}}> Privacy Policy </a>

<br />
<a className="nav-item" as={Link}   href="/quote"  style={{fontSize: `1rem`,  padding: `0 0.5rem`,  lineHeight: `1.15rem`, textDecoration:`underline`}}>Find Your Quote </a>

    </ul>

    <p className="text-center text-body-secondary">
      
    <a as={Link} href="/" style={{  textAlign: `center`, width: `100%`,  }}>
<span style={{fontStyle: `italic`,}}>On The Internet
 Since 1994 <br /></span>
   findmyinsurance.com 
    
    </a></p>
  </footer>
</div>
 
    </div>
  </footer>
  </>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
